<template>
	<div>
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					EC Condition
				</h2>
			</CCol>
		</CRow>

		<CRow>
			<CCol md="12">
				<div
					v-for="{value, label} in ecCodeConditionOptions"
					:key="`type-${value}`"
				>
					<CInputRadio
						:label="label"
						:value="value"
						:checked="formData.type === value"
						name="apply-with"
						class="mt-3"
						custom
						@update:checked="handleChangeApplyWith(value)"
					/>
				</div>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import {
	EC_CODE_CONDITION_OPTIONS,
} from '../enums/promotions';

export default {
	name: 'PromotionEcCondition',

	props: {
		dataCondition: {
			type: String,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			formData: {
				type: null,
			},
		};
	},

	computed: {
		ecCodeConditionOptions() {
			return EC_CODE_CONDITION_OPTIONS.map((option) => ({
				...option,
				...(this.isEditMode ? { props: { disabled: true } } : null), // Disabled option if edit mode.
			}));
		},
	},

	mounted() {
		// set default value
		const type = this.dataCondition || EC_CODE_CONDITION_OPTIONS[0].value;
		this.handleChangeApplyWith(type);
	},

	methods: {
		handleChangeApplyWith(value) {
			this.formData.type = value;
			this.$emit('onUpdate', value);
		},
	},
};
</script>
