<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="title"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetSingleProductBySKU
							ref="form-sku"
							:product="localProduct"
							:disabled="disabledForm"
							@onGetProduct="handleGet"
							@onRemove="handleRemove"
						>
							<template #no-item>
								<div
									class="no-item d-flex text-center"
									data-test-id="no-item"
								>
									<div class="d-block">
										<div class="no-sku font-weight-bolder color-black-45">
											No SKU yet
										</div>
										<div class="typo-body-2 color-black-45">
											Add "SKU number" in order to specific SKU
										</div>
									</div>
								</div>
							</template>
						</FormGetSingleProductBySKU>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					@onCancel="handleCancel"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
	</div>
</template>

<script>
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import FormGetSingleProductBySKU from '@/components/FormGetSingleProductBySKU.vue';

export default {
	name: 'ModalSingleSKU',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		FormGetSingleProductBySKU,
	},
	props: {
		title: {
			type: String,
			default: 'Select SKU',
		},
		product: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			isShow: false,
			localProduct: null,
		};
	},
	computed: {
		disabledForm() {
			return !!this.localProduct;
		},
	},
	created() {
		this.localProduct = this.product ? { ...this.product } : null;
	},
	methods: {
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
			this.$refs['form-sku'].resetState();
		},
		handleGet(product) {
			this.localProduct = product ? { ...product } : null;
		},
		handleRemove() {
			this.localProduct = null;
		},
		handleCancel() {
			this.localProduct = this.product ? { ...this.product } : null;
			this.close();
		},
		handleClear() {
			this.localProduct = null;
		},
		handleSubmit() {
			this.isShow = false;
			this.$emit('onConfirm', this.localProduct);
		},
	},
};
</script>

<style lang="scss" scoped>
	.no-item {
		min-height: rem(64);
		justify-content: center;
		align-items: center;
	}

	::v-deep .list-item {
		min-height: rem(64);
		padding: 0;
		margin: 0;
	}
</style>
