<template>
	<div>
		<CForm
			class="d-block"
			@submit.prevent="handleSubmit"
		>
			<CRow>
				<CCol md="3">
					<BaseDropDown
						v-model="localRule"
						:options="CUSTOMER_CONDITION_RULE_OPTIONS"
						:searchable="false"
						:allow-empty="false"
						class="select-custom"
						label="label"
						track-by="value"
						label-drop-down="Rule"
						@input="handleRuleChange"
					/>
				</CCol>
				<CCol md="9" class="pl-1">
					<label>Phone number</label>
					<div class="d-flex align-items-start">
						<CInput
							v-model.trim="inputPhoneNumber"
							:is-valid="isValid && null"
							:label="label"
							:placeholder="placeholder"
							:description="$t('global.seperateBySemicolon')"
							:invalid-feedback="$t('global.error.invalid')"
							class="flex-fill"
							add-input-classes="form-get-phone-number-input"
							data-test-id="input-phone-number"
						/>
						<CButton
							:disabled="!isValid"
							type="submit"
							class="btn-add ml-3"
							color="secondary"
							data-test-id="button-add"
						>
							Add
						</CButton>
					</div>
				</CCol>
			</CRow>
		</CForm>
		<slot v-if="!phoneNumberList.length" name="no-item">
		</slot>
		<ul
			v-else
			class="list-item"
			:class="{ 'show-border': hasBorder }"
		>
			<h6 class="typo-body-2">
				Phone Number
			</h6>
			<p class="show-text">
				{{ showText }}
			</p>
		</ul>
	</div>
</template>

<script>
import { multipleNumberFormat } from '../assets/js/validators';
import { CUSTOMER_CONDITION_RULE_OPTIONS } from '../enums/promotions';

export default {
	name: 'FormGetPhoneNumber',
	validations() {
		return {
			inputPhoneNumber: {
				format: multipleNumberFormat,
			},
		};
	},
	props: {
		label: {
			type: String,
			default: null,
		},
		rule: {
			type: String,
			default: null,
		},
		phoneNumberList: {
			type: Array,
			default: () => [],
		},
		hasBorder: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: 'Paste or type phone number here',
		},
	},
	data() {
		return {
			CUSTOMER_CONDITION_RULE_OPTIONS,
			inputPhoneNumber: '',
			localRule: null,
		};
	},
	computed: {
		isValid() {
			if (!this.inputPhoneNumber) {
				return true;
			}

			return !this.$v.inputPhoneNumber.$invalid;
		},
		showText() {
			return this.phoneNumberList.join('; ');
		},
	},
	methods: {
		resetState() {
			this.inputPhoneNumber = '';
			this.localRule = this.rule ? CUSTOMER_CONDITION_RULE_OPTIONS.find((rule) => rule.value === this.rule) : CUSTOMER_CONDITION_RULE_OPTIONS[0];
		},
		getListFromInput(input) {
			let list = input.split(';');

			// trim all items and remove null
			list = list.map((item) => item.trim()).filter((item) => item);

			// remove duplicates
			return [...new Set(list)];
		},
		handleRuleChange() {
			this.$emit('onGetPhoneNumber', { rule: this.localRule.value, list: this.phoneNumberList });
		},
		handleSubmit() {
			if (!this.inputPhoneNumber) {
				return;
			}

			const mergedInput = `${this.showText}; ${this.inputPhoneNumber}`;
			const list = this.getListFromInput(mergedInput);
			this.$emit('onGetPhoneNumber', { rule: this.localRule.value, list });
			this.resetState();
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-group-text {
		padding-right: 0;
	}

	::v-deep .form-get-phone-number-input {
		margin-right: rem(100);
	}

	.btn-add {
		min-width: rem(84);
	}

	.list-item {
		padding: rem(20) rem(16) 0;
		max-height: rem(320);
		overflow: auto;
	}

	.show-text {
		font-size: rem($font-size-caption);
		font-weight: $font-weight-semi-bold;
		color: $color-black-45;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}
</style>
