<template>
	<div>
		<CForm class="d-flex align-items-start" @submit.prevent="handleSubmit">
			<CInput
				v-model.trim="inputCustomerId"
				:is-valid="isValid && null"
				:label="label"
				:placeholder="placeholder"
				:description="$t('global.seperateBySemicolon')"
				:invalid-feedback="$t('global.error.invalid')"
				class="flex-fill"
				add-input-classes="form-get-customer-id-input"
				data-test-id="input-customer-id"
			/>
			<CButton
				:disabled="!isValid"
				type="submit"
				class="btn-add ml-2"
				color="secondary"
				data-test-id="button-add"
			>
				Add
			</CButton>
		</CForm>
		<slot v-if="!customerIdList.length" name="no-item">
		</slot>
		<ul
			v-else
			class="list-item"
			:class="{ 'show-border': hasBorder }"
		>
			<h6 class="typo-body-2">
				Customer ID
			</h6>
			<p class="show-text">
				{{ showText }}
			</p>
		</ul>
	</div>
</template>

<script>
import { helpers } from 'vuelidate/lib/validators';
import { multipleNumber } from '../regex/general';

export default {
	name: 'FormGetCustomerId',
	validations() {
		return {
			inputCustomerId: {
				format: helpers.regex('number', multipleNumber),
			},
		};
	},
	props: {
		label: {
			type: String,
			default: null,
		},
		customerIdList: {
			type: Array,
			default: () => [],
		},
		hasBorder: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: 'Paste or type customer ID here',
		},
	},
	data() {
		return {
			inputCustomerId: '',
		};
	},
	computed: {
		isValid() {
			if (!this.inputCustomerId) {
				return true;
			}

			return !this.$v.inputCustomerId.$invalid;
		},
		showText() {
			return this.customerIdList.join('; ');
		},
	},
	methods: {
		resetState() {
			this.inputCustomerId = '';
		},
		getListFromInput(input) {
			let list = input.split(';');

			// trim all items and remove null
			list = list.map((item) => item.trim()).filter((item) => item);

			// remove duplicates
			return [...new Set(list)];
		},
		async handleSubmit() {
			if (!this.inputCustomerId) {
				return;
			}

			const mergedInput = `${this.showText}; ${this.inputCustomerId}`;
			const list = this.getListFromInput(mergedInput);
			this.$emit('onGetCustomerId', list);
			this.resetState();
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-group-text {
		padding-right: 0;
	}

	::v-deep .form-get-customer-id-input {
		margin-right: rem(100);
	}

	.btn-add {
		min-width: rem(84);
	}

	.list-item {
		padding: rem(20) rem(16) 0;
		max-height: rem(320);
		overflow: auto;
	}

	.show-text {
		font-size: rem($font-size-caption);
		font-weight: $font-weight-semi-bold;
		color: $color-black-45;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}
</style>
