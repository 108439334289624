<template>
	<div class="form-container">
		<!-- Reward -->
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					Reward
				</h2>
			</CCol>
		</CRow>

		<CRow>
			<CCol md="12">
				<label class="mt-2">Applies discount allocation to</label>
				<div
					v-for="{value, label} in applyToOptions"
					:key="`applyto${value}`"
				>
					<CInputRadio
						:label="label"
						:value="value"
						:checked="formData.applyTo === value"
						:description="getApplyToDescriptionText(value)"
						name="apply-to"
						class="mt-3"
						custom
						@update:checked="handleChangeApplyTo(value)"
					/>
					<div
						v-if="showApplyToProductBundleCondition(value)"
						class="condition-value pt-2 pl-4"
					>
						<CInputCheckbox
							v-for="product in formData.productBundleCondition"
							:key="product.id"
							:label="product.name"
							:description="product.sku"
							:checked.sync="product.checked"
							class="mb-2"
							custom
							@update:checked="handleApplyToProductConditionItemChange($event, product)"
						/>
					</div>
					<div
						v-if="showApplyToItemInput(value)"
						class="condition-value pt-2 pl-4"
					>
						<CButton
							type="button"
							class="btn ml-1 mr-3"
							color="secondary"
							@click="handleApplyToItemClick(value)"
						>
							{{ applyToItems.length ? 'Edit' : 'Add' }} {{ APPLY_TO_ITEM_LABELS[value] }}
						</CButton>
						<span>{{ getSummaryOfApplyToItems(value) }}</span>
					</div>
				</div>
			</CCol>
		</CRow>
		<!-- End Reward -->

		<!-- Discount value for TIER type -->
		<template v-if="isTypeTier">
			<CRow class="mt-5 mb-2">
				<CCol md="12">
					<h3 class="typo-h5 position-relative">
						Discount value
					</h3>
				</CCol>
			</CRow>
			<CRow v-if="showApplyEvery" class="mt-2">
				<CCol>
					<label>
						Apply discount for every XXX purchase amount/quantity
					</label>
				</CCol>
				<CCol md="3">
					<div class="form-group-switch">
						<CSwitch
							:checked.sync="formData.applyDiscountEvery"
							variant="3d"
							size="sm"
							class="switch-custom"
							color="success"
						/>
						<span class="typo-body-2">
							{{ formData.applyDiscountEvery ? 'On' : 'Off' }}
						</span>
					</div>
				</CCol>
			</CRow>
			<CRow class="mt-2">
				<CCol md="6">
					<label>
						Discount value type
					</label>
				</CCol>
				<CCol md="6">
					<BaseDropDown
						v-model="$v.formData.discountType.$model"
						:options="discountTypeOptions"
						:searchable="false"
						:allow-empty="false"
						class="select-custom"
						label="label"
						track-by="value"
						@input="handleDiscountTypeChange($event)"
					/>
				</CCol>
			</CRow>

			<CRow class="mb-3">
				<CCol md="8">
					<label>Customer get reward from</label>
				</CCol>
				<CCol md="4" class="text-right">
					<CInputRadioGroup
						:options="CUSTOMER_REWARD_TYPE_OPTIONS"
						:checked="formData.customerRewardType"
						custom
						inline
						size="sm"
						class="radio-group"
						name="customer-reward-type"
						@update:checked="handleChangeCustomerRewardType"
					/>
				</CCol>
			</CRow>

			<template v-if="formData.tiers">
				<div
					v-for="(tier, index) in formData.tiers"
					:key="index"
					class="tier-condition-item mb-4"
				>
					<CRow>
						<CCol>
							<label>Tier {{ Number(index) + 1 }}</label>
						</CCol>
					</CRow>
					<CRow>
						<CCol md="2">
							<label>Condition</label>
						</CCol>
						<CCol md="10" class="typo-body-2">
							{{ getConditionApplyText(tier.cartMinimumType, tier.minimumPurchaseAmount) }}
						</CCol>
					</CRow>
					<CRow>
						<CCol md="2">
							<label>Reward</label>
						</CCol>
						<CCol md="10" class="typo-body-2">
							{{ getRewardApplyText(tier.cartMinimumType, tier.minimumPurchaseAmount) }}
						</CCol>
					</CRow>
					<PromotionDiscountValue
						:default-data="tier"
						:discount-type="formData.discountType"
						@update:is-valid="handleValidChange($event, index)"
					/>
				</div>
			</template>
		</template>

		<!-- Discount value for General, Coupon and bundle -->
		<template v-else>
			<CRow class="mt-5 mb-2">
				<CCol md="12">
					<h3 class="typo-h5 position-relative">
						Discount value
					</h3>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<BaseDropDown
						v-model="$v.formData.discountType.$model"
						:options="discountTypeOptions"
						:searchable="false"
						:allow-empty="false"
						class="select-custom"
						label="label"
						track-by="value"
						label-drop-down="Type"
						@input="handleDiscountTypeChange($event)"
					/>
				</CCol>
			</CRow>

			<PromotionDiscountValue
				:key="formData.discountType && formData.discountType.value"
				:default-data="formData"
				:discount-type="formData.discountType"
				@update:is-valid="handleValidChange($event)"
			/>

			<CRow v-if="showApplyEvery" class="mt-4">
				<CCol>
					<label>
						Apply discount for every XXX purchase amount/quantity
					</label>
				</CCol>
				<CCol md="3">
					<div class="form-group-switch">
						<CSwitch
							:checked.sync="formData.applyDiscountEvery"
							variant="3d"
							size="sm"
							class="switch-custom"
							color="success"
						/>
						<span class="typo-body-2">
							{{ formData.applyDiscountEvery ? 'On' : 'Off' }}
						</span>
					</div>
				</CCol>
			</CRow>
			<CRow v-if="showApplyEvery && formData.applyDiscountEvery">
				<CCol md="4">
					<BaseDropDown
						v-model="$v.formData.applyDiscountEveryType.$model"
						:options="APPLY_DISCOUNT_EVERY_TYPE_OPTIONS"
						:searchable="false"
						:allow-empty="false"
						:is-valid="!$v.formData.applyDiscountEveryType.$error"
						:invalid-feedback="$t('global.error.required')"
						class="select-custom"
						label="label"
						track-by="value"
						label-drop-down="Every"
						@input="handleApplyDiscountEveryTypeChange"
					/>
				</CCol>
				<template
					v-if="isApplyEveryPurchaseAmount"
				>
					<CCol md="4">
						<BaseInputNumber
							key="PURCHASE_AMOUNT"
							v-model="$v.formData.applyDiscountEveryValue.$model"
							:decimal-scale="2"
							:is-valid="!$v.formData.applyDiscountEveryValue.$error"
							invalid-feedback="Value must be greater than 0"
							append-text="฿"
							label="Purchase amount*"
							text-align="left"
							placeholder="Type purchase amount"
						/>
					</CCol>
					<CCol md="4">
						<BaseInputNumber
							key="PURCHASE_AMOUNT_LIMIT"
							v-model="$v.formData.applyDiscountEveryLimitValue.$model"
							:decimal-scale="2"
							append-text="฿"
							label="Total purchase amount limit"
							text-align="left"
							placeholder="Type purchase amount"
							allow-null-value
						/>
					</CCol>
				</template>
				<template v-else>
					<CCol md="4">
						<BaseInputNumber
							key="QUANTITY"
							v-model="$v.formData.applyDiscountEveryValue.$model"
							:decimal-scale="0"
							:is-valid="!$v.formData.applyDiscountEveryValue.$error"
							invalid-feedback="Value must be greater than 0"
							append-text="Qty."
							label="Quantity*"
							text-align="left"
							placeholder="Type quantity"
						/>
					</CCol>
					<CCol md="4">
						<BaseInputNumber
							key="QUANTITY_LIMIT"
							v-model="$v.formData.applyDiscountEveryLimitValue.$model"
							append-text="Qty."
							label="Total quantity limit"
							text-align="left"
							placeholder="Type quantity"
							allow-null-value
						/>
					</CCol>
				</template>
			</CRow>
		</template>
		<!-- End Discount value -->

		<ModalCategory
			ref="modal-category"
			title="Select categories"
			:selected-ids="applyToItems"
			@onSubmit="handleApplyToItemListChange"
		/>
		<ModalSkuList
			ref="modal-sku"
			title="Select SKUs"
			:sku-list="applyToItems"
			@onConfirm="handleApplyToItemListChange"
		/>
	</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { validationMixin } from 'vuelidate';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import PromotionDiscountValue from '@/components/PromotionDiscountValue.vue';
import ModalCategory from '@/components/ModalCategory.vue';
import ModalSkuList from '@/components/ModalSkuList.vue';

import {
	APPLY_TO_KEYS,
	APPLY_TO_LABELS,
	APPLY_TO_OPTIONS,
	APPLY_TO_ITEM_LABELS,
	DISCOUNT_TYPE_KEYS,
	DISCOUNT_TYPE_OPTIONS,
	APPLY_DISCOUNT_EVERY_TYPE_KEYS,
	APPLY_DISCOUNT_EVERY_TYPE_LABELS,
	APPLY_DISCOUNT_EVERY_TYPE_OPTIONS,
	CART_CONDITION_TYPES,
	PROMOTION_TYPES,
	CUSTOMER_REWARD_TYPES,
	CUSTOMER_REWARD_TYPE_OPTIONS,
} from '../enums/promotions';
import { numberFormat, priceFormat, cloneDeep } from '../assets/js/helpers';

const needApplyToList = [
	APPLY_TO_KEYS.SPECIFIC_CATEGORY,
	APPLY_TO_KEYS.SPECIFIC_SKU,
	APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU,
];

export default {
	name: 'PromotionStepReward',

	components: {
		PromotionDiscountValue,
		ModalCategory,
		ModalSkuList,
	},

	validations() {
		let applyDiscountEveryType = {};
		let applyDiscountEveryValue = {};
		const applyDiscountEveryLimitValue = {};

		// Tier will not require any value of Apply every
		if (this.showApplyEvery && this.formData.applyDiscountEvery && !this.isTypeTier) {
			applyDiscountEveryValue = {
				required,
				minValue: minValue(1),
			};
			applyDiscountEveryType = { required };
		}

		return {
			formData: {
				applyTo: {
					required,
				},
				applyToItems: {
					required: requiredIf(() => this.needApplyToItems || this.needApplyToProductBundleConditionItems),
				},
				discountType: {
					required,
				},
				applyDiscountEveryType,
				applyDiscountEveryValue,
				applyDiscountEveryLimitValue,
			},
		};
	},

	mixins: [validationMixin],

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
		promotionType: {
			type: String,
			default: PROMOTION_TYPES.GENERAL,
		},
	},

	data() {
		const {
			applyTo = null,
			applyToItems = null,
			discountType = DISCOUNT_TYPE_OPTIONS[1],
			discount = null,
			maximumDiscount = null,
			discountItemList = [],
			applyDiscountEvery = false,
			applyDiscountEveryType = APPLY_DISCOUNT_EVERY_TYPE_OPTIONS[0],
			applyDiscountEveryValue = null,
			applyDiscountEveryLimitValue = null,
			customerRewardType = CUSTOMER_REWARD_TYPES.ALL, // (Tier only) Default is 'All'
		} = this.defaultData || {};

		return {
			APPLY_TO_KEYS,
			APPLY_TO_LABELS,
			APPLY_TO_OPTIONS,
			APPLY_TO_ITEM_LABELS,
			DISCOUNT_TYPE_OPTIONS,
			APPLY_DISCOUNT_EVERY_TYPE_KEYS,
			APPLY_DISCOUNT_EVERY_TYPE_LABELS,
			APPLY_DISCOUNT_EVERY_TYPE_OPTIONS,
			CUSTOMER_REWARD_TYPE_OPTIONS,
			formData: {
				customerRewardType,
				applyTo,
				applyToItems,
				discountType,
				discount,
				maximumDiscount,
				discountItemList,
				applyDiscountEvery,
				applyDiscountEveryType,
				applyDiscountEveryValue,
				applyDiscountEveryLimitValue,
				isDiscountValueValid: false,
			},
		};
	},

	computed: {
		applyToOptions() {
			let hideOptionValues = [];

			if (this.isTypeBundle) {
				hideOptionValues = [
					APPLY_TO_KEYS.PRODUCT_SELECTED_IN_CONDITION,
					APPLY_TO_KEYS.PRODUCT_BUNDLE2_SELECTED_IN_CONDITION,
					APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU,
					APPLY_TO_KEYS.BUNDLE2_SET_FREEBIE,
				];
			} else if (this.isTypeBundle2) {
				hideOptionValues = [
					APPLY_TO_KEYS.ENTIRE_ORDER,
					APPLY_TO_KEYS.PRODUCT_SELECTED_IN_CONDITION,
					APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION,
					APPLY_TO_KEYS.SPECIFIC_SKU,
					APPLY_TO_KEYS.SPECIFIC_CATEGORY,
					APPLY_TO_KEYS.SHIPPING_FEE,
				];
			} else {
				hideOptionValues = [
					APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION,
					APPLY_TO_KEYS.PRODUCT_BUNDLE2_SELECTED_IN_CONDITION,
					APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU,
					APPLY_TO_KEYS.BUNDLE2_SET_FREEBIE,
				];
			}

			return APPLY_TO_OPTIONS.filter((option) => !hideOptionValues.includes(option.value));
		},
		applyToItems() {
			return this.formData.applyToItems || [];
		},
		applyToProductBundleConditionItems() {
			return (this.formData.productBundleCondition || [])
				.filter(({ checked }) => checked)
				.map(({ sku }) => sku);
		},
		needApplyToItems() {
			return this.formData.applyTo && needApplyToList.includes(this.formData.applyTo);
		},
		// Bundle, When apply to is Product selected in condition
		needApplyToProductBundleConditionItems() {
			if (!this.isTypeBundle) {
				return false;
			}

			if (this.formData.applyTo !== APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION) {
				return false;
			}

			if (!this.formData.productBundleCondition) {
				return false;
			}

			return true;
		},
		discountTypeOptions() {
			let options = [];
			if (this.isApplyToShippingFee) {
				options = this.DISCOUNT_TYPE_OPTIONS.filter((option) => [
					DISCOUNT_TYPE_KEYS.FREE_SHIPPING,
					DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT,
					DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT,
				].includes(option.value));
			} else if (!this.isApplyToEntireOrder) {
				options = this.DISCOUNT_TYPE_OPTIONS.filter((option) => ![
					DISCOUNT_TYPE_KEYS.COUPON,
					DISCOUNT_TYPE_KEYS.FREE_SHIPPING,
				].includes(option.value));
			} else {
				options = this.DISCOUNT_TYPE_OPTIONS.filter((option) => option.value !== DISCOUNT_TYPE_KEYS.FREE_SHIPPING);
			}

			// TODO: Hide DISCOUNT_TYPE_KEYS Bundle 2.0
			options = options.filter((option) => option.value !== DISCOUNT_TYPE_KEYS.BUNDLE2_SET_FREEBIE);

			if (this.isFreebieBundle2) {
				return this.DISCOUNT_TYPE_OPTIONS.filter((option) => [
					DISCOUNT_TYPE_KEYS.BUNDLE2_SET_FREEBIE,
				].includes(option.value));
			}

			// TODO: Will display freebie back when BE ready (remove this if)
			if (this.isTypeBundle2) {
				return options.filter((option) => option.value !== DISCOUNT_TYPE_KEYS.FREEBIE && option.value !== DISCOUNT_TYPE_KEYS.BUNDLE2_SET_FREEBIE);
			}

			return options;
		},
		showApplyEvery() {
			if (this.isFixedPriceDiscount || this.isApplyToShippingFee || this.isTypeBundle || this.isTypeBundle2) {
				return false;
			}

			return true;
		},
		isApplyToEntireOrder() {
			return this.formData.applyTo === APPLY_TO_KEYS.ENTIRE_ORDER;
		},
		isApplyToShippingFee() {
			return this.formData.applyTo === APPLY_TO_KEYS.SHIPPING_FEE;
		},
		isPercentageDiscount() {
			return this.formData.discountType && this.formData.discountType.value === DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT;
		},
		isFixedPriceDiscount() {
			return (
				this.formData.discountType && this.formData.discountType.value === DISCOUNT_TYPE_KEYS.FIXED_PRICE
			);
		},
		isTypeTier() {
			return this.promotionType === PROMOTION_TYPES.TIER;
		},
		isTypeBundle() {
			return this.promotionType === PROMOTION_TYPES.BUNDLE;
		},
		isTypeBundle2() {
			return this.promotionType === PROMOTION_TYPES.BUNDLE_GROUP;
		},
		isApplyEveryPurchaseAmount() {
			return this.formData.applyDiscountEveryType && this.formData.applyDiscountEveryType.value === APPLY_DISCOUNT_EVERY_TYPE_KEYS.PURCHASE_AMOUNT;
		},
		isFreebieBundle2() {
			return this.isTypeBundle2 && this.formData.applyTo === APPLY_TO_KEYS.BUNDLE2_SET_FREEBIE;
		},
	},

	watch: {
		defaultData: {
			deep: true,
			handler(data, prevData) {
				// TODO: Change default discount type
				// if (this.isFreebieBundle2) {
				// 	this.$set(this.formData, 'discountType', DISCOUNT_TYPE_OPTIONS[6]);
				// } else {
				// 	this.$set(this.formData, 'discountType', DISCOUNT_TYPE_OPTIONS[1]);
				// }

				if (data !== prevData) {
					if (this.isTypeTier && data.tierCondition) {
						this.formData = {
							...this.formData,
							tiers: cloneDeep(this.getTiers(data)),
						};
					} else if (this.isTypeBundle) {
						const { productBundleCondition } = data;
						// Initial Product select condition - SKU item check box status,
						if (productBundleCondition && productBundleCondition.length) {
							productBundleCondition.forEach((product) => {
								// if there are not any selection, set selected all as default
								if (isEmpty(this.formData.applyToItems)) {
									product.checked = true;
								} else {
									product.checked = (this.formData.applyToItems || []).includes(product.sku);
								}
							});
						}

						this.$set(this.formData, 'productBundleCondition', cloneDeep(productBundleCondition));

						// Set apply to items with list of selected sku
						if (this.formData.applyTo === APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION) {
							this.$set(this.formData, 'applyToItems', [...this.applyToProductBundleConditionItems]);
						}
					}
				}
			},
		},
		formData: {
			deep: true,
			handler(data) {
				this.$emit(
					'update:is-valid',
					{
						valid: !this.$v.$invalid && data.isDiscountValueValid,
						data,
					},
				);
			},
		},
	},

	created() {
		// Set default apply to option to be first option
		if (!this.formData.applyTo) {
			this.formData = {
				...this.formData,
				applyTo: this.applyToOptions?.[0]?.value ?? null,
			};
		}
	},

	methods: {
		...mapActions({
			preSelectCategories: 'categorySelector/preSelectCategories',
		}),
		resetDiscountValues() {
			this.formData = {
				...this.formData,
				discountType: null,
				discount: null,
				maximumDiscount: null,
				discountItemList: [],
				applyDiscountEvery: false,
				applyDiscountEveryType: APPLY_DISCOUNT_EVERY_TYPE_OPTIONS[0],
				applyDiscountEveryValue: null,
				applyDiscountEveryLimitValue: null,
			};
		},
		getApplyToDescriptionText(value) {
			if (this.isTypeBundle && value === APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION) {
				return '(Discount will only effect on SKU in bundle set.)';
			}

			return null;
		},
		getConditionApplyText(cartMinimumType, value) {
			if (!this.isTypeTier) {
				return '';
			}

			if (cartMinimumType === CART_CONDITION_TYPES.QUANTITY) {
				return `Minimum item quantity of ${numberFormat(value)}`;
			}
			return `Minimum purchase amount of ${priceFormat(value)}`;
		},
		getRewardApplyText(cartMinimumType, value) {
			if (!this.isTypeTier) {
				return '';
			}

			let text = '';
			if (cartMinimumType === CART_CONDITION_TYPES.QUANTITY) {
				text = `item quantity of ${numberFormat(value)}`;
			} else {
				text = `purchase amount of ${priceFormat(value)}`;
			}

			return this.formData.applyDiscountEvery ? `Every ${text}` : `Minimum ${text}`;
		},
		handleChangeApplyTo(value) {
			this.formData.applyTo = value;
			this.formData.applyToItems = null;

			// If the option is not in list, reset value to be null
			const hasOptionInList = this.discountTypeOptions.some((option) => option.value === this.formData?.discountType?.value);
			if (!hasOptionInList) {
				this.resetDiscountValues();
			}

			// Promotion bundle type
			if (this.formData.applyTo === APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION) {
				const { productBundleCondition } = this.formData;
				// Reset check box status
				if (productBundleCondition && productBundleCondition.length) {
					productBundleCondition.forEach((product) => {
						// if there are not any selection, set selected all as default
						if (isEmpty(this.formData.applyToItems)) {
							product.checked = true;
						} else {
							product.checked = (this.formData.applyToItems || []).includes(product.sku);
						}
					});
				}

				this.$set(this.formData, 'applyToItems', [...this.applyToProductBundleConditionItems]);
			}
		},
		handleDiscountTypeChange(option) {
			this.formData.maximumDiscount = null;
			this.formData.discountItemList = [];

			// Reset Apply every on Fixed Price discount
			if (this.isTypeTier && this.isFixedPriceDiscount) {
				this.formData.applyDiscountEvery = false;
				this.formData.applyDiscountEveryValue = null;
				this.formData.applyDiscountEveryLimitValue = null;
			}

			if (this.isTypeTier) {
				this.resetTiers();
			}

			if (option && option.value === DISCOUNT_TYPE_KEYS.COUPON) {
				this.formData.applyTo = APPLY_TO_KEYS.ENTIRE_ORDER;
			}
		},
		handleApplyDiscountEveryTypeChange() {
			this.formData.applyDiscountEveryValue = null;
			this.formData.applyDiscountEveryLimitValue = null;
		},
		showApplyToProductBundleCondition(value) {
			if (!this.needApplyToProductBundleConditionItems) {
				return false;
			}

			return value === APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION;
		},
		showApplyToItemInput(option) {
			return this.needApplyToItems && needApplyToList.includes(option) && option === this.formData.applyTo;
		},
		getSummaryOfApplyToItems(option) {
			if (!option) {
				return '';
			}

			// No selected any value
			if (option && !this.applyToItems.length) {
				return `No ${APPLY_TO_ITEM_LABELS[option]} selected`;
			}

			// Fully summary
			if (Array.isArray(this.applyToItems)) {
				return `${numberFormat(this.applyToItems.length)} ${APPLY_TO_ITEM_LABELS[option]}(s) selected`;
			}

			// Implicit value
			return this.applyToItems;
		},
		handleApplyToItemClick(value) {
			switch (value) {
				case APPLY_TO_KEYS.SPECIFIC_CATEGORY:
					this.preSelectCategories(this.applyToItems);
					this.$refs['modal-category'].open();
					break;
				case APPLY_TO_KEYS.SPECIFIC_SKU:
				case APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU:
					this.$refs['modal-sku'].open();
					break;
				default: break;
			}
		},
		handleApplyToItemListChange(list) {
			let values = null;
			switch (this.formData.applyTo) {
				case APPLY_TO_KEYS.SPECIFIC_CATEGORY:
					values = list.map((item) => item.id);
					break;
				case APPLY_TO_KEYS.SPECIFIC_SKU:
				case APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU:
					values = list;
					break;
				default: break;
			}

			this.formData.applyToItems = values;
		},
		handleApplyToProductConditionItemChange(value, product) {
			const selectedIndex = this.formData.productBundleCondition.findIndex((productBundle) => productBundle.id === product.id);
			if (selectedIndex < 0) {
				return;
			}

			this.formData.productBundleCondition[selectedIndex].checked = value;
			// HACK: Trigger object change by assign new object value with clone new object technique
			this.formData.productBundleCondition = [...this.formData.productBundleCondition];
			this.formData.applyToItems = [...this.applyToProductBundleConditionItems];
		},
		handleChangeCustomerRewardType(value) {
			this.formData.customerRewardType = value;
		},
		getTiers(data) {
			if (!data) {
				return null;
			}

			return data.tierCondition.map((item, index) => {
				let currentTier = null;
				if (this.formData.tiers && index < this.formData.tiers.length) {
					currentTier = this.formData.tiers[index];
				}

				const discount = currentTier ? currentTier.discount : item.discount;
				const maximumDiscount = currentTier ? currentTier.maximumDiscount : item.maximumDiscount;
				const discountItemList = currentTier ? currentTier.discountItemList : item.discountItemList;
				return {
					cartMinimumType: item.cartMinimumType,
					minimumPurchaseAmount: item.minimumPurchaseAmount,
					discount: discount ?? null,
					maximumDiscount: maximumDiscount ?? null,
					discountItemList: discountItemList || [],
					valid: currentTier?.valid || true,
				};
			});
		},
		resetTiers() {
			if (!this.formData.tiers) {
				return;
			}

			const tiers = this.formData.tiers.map((item) => {
				return {
					...item,
					discount: null,
					maximumDiscount: null,
					discountItemList: [],
					valid: false,
				};
			});

			this.$set(this.formData, 'tiers', cloneDeep(tiers));
		},
		handleValidChange(value, index) {
			const { valid, data: formData } = value;

			if (this.isTypeTier) {
				this.formData.tiers[index].discount = formData.discount;
				this.formData.tiers[index].maximumDiscount = formData.maximumDiscount;
				this.formData.tiers[index].discountItemList = formData.discountItemList;
				this.formData.tiers[index].valid = valid;

				this.formData.isDiscountValueValid = !this.formData.tiers.some((tier) => !tier.valid);
			} else {
				this.formData.discount = formData.discount;
				this.formData.maximumDiscount = formData.maximumDiscount;
				this.formData.discountItemList = formData.discountItemList;
				this.formData.isDiscountValueValid = valid;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);

		// HACK: Made radio input's description align same line with label
		::v-deep .custom-radio {
			.form-text {
				display: inline;
				position: relative;
				top: rem(-3);
				left: rem(12);
			}
		}
	}

	.tier-condition {
		// .tier-condition-item
		&-item {
			background: $color-gray-100;
			border-radius: rem(4);
			padding: rem(16);
		}
	}
</style>
